<script>
import ChatControls from '@/mixins/chatControls.js'
import ScoreFunctions from '@/mixins/scoreFunctions.js'
import ConnectToEnfant from '@/mixins/connectEleve.js'
import FormatDate from '@/mixins/formatDate.js'
import noteEnfant from '@/components/enfants/noteEnfant'

export default {
  name: 'EnfantCard',
  mixins: [ChatControls, ScoreFunctions, FormatDate, ConnectToEnfant],
  components: {
    noteEnfant
  },
  props: {
    user: { type: Object, required: true },
    accueil: { type: Boolean, required: false, default: false },
    id: { type: String, required: false }
  },
  computed: {
    ecoles () {
      return this.user.compagnies
    }
  },
  data () {
    return {
      loadingCalc: true,
      markGlobal: 0,
      userStat: null,
      listeExo: [],
      loading: false
    }
  },
  apollo: {
    // user: {
    //   query: require('@/graphql/queries/compte/userEnfant.gql'),
    //   client: 'compte',
    //   variables () {
    //     return {
    //       id: this.id
    //     }
    //   },
    //   update (data) {
    //     this.loading = false
    //     return data.user
    //   },
    //   watchLoading (isLoading) {
    //     this.loading = isLoading
    //   },
    //   skip () {
    //     return !this.id
    //   }
    // },
    userStat: {
      query: require('@/graphql/queries/plus/userNote.gql'),
      client: 'plus',
      variables () {
        return {
          id: this.id,
          statuts: ['FINISHED'],
          limit: 100
        }
      },
      update (data) {
        if (data.user) {
          this.calcMoyenne(data)
        }
        return data.user
      }
    }
  },
  methods: {
    calcMoyenne (data) {
      this.loadingCalc = true
      let mark = 0
      this.listeExo = []
      // const nb_elem = data.user.exercice_graphs_classe_maison.length + data.user.exercice_graphs.length
      if (data.user.exercice_graphs_classe_maison.length > 0) {
        data.user.exercice_graphs_classe_maison.forEach((e) => {
          if (e.mark && !e.graph.tag_invisibles.find(t => t.name.inlucdes('Saglac' || t.name.inlucdes('Saguenay')))) {
            mark += e.mark
            this.listeExo.push(e)
          }
        })
      }
      if (data.user.exercice_graphs.length > 0) {
        data.user.exercice_graphs.forEach((e) => {
          if (e.mark) {
            mark += e.mark
            this.listeExo.push(e)
          }
        })
      }
      if (mark > 0) {
        this.markGlobal = Math.round(mark / this.listeExo.length, 2)
      } else {
        this.markGlobal = 0
      }
      this.loadingCalc = false
    }
  }
}
</script>

<template lang='pug'>
v-card.enfant-card.secondary--text(:elevation='accueil ? "0" :"5"', :class='{"accueil-style" : accueil}')
  template(v-if='loading')
    v-list-item.enfant-card__main-content(depressed, flat)
      v-skeleton-loader.w-100(type="list-item-avatar-three-line, article, actions")
  template(v-else-if='user')
    //- pre {{user}}
    v-list-item.enfant-card__main-content.animated.fadeIn(depressed, flat)
      v-list-item-avatar.mr3(size='60', style='margin-right:10px', :color='$store.state.Preferences.darkMode ? "white" : "light"', v-if='!$store.state.App.mobileTemplate')
        img(v-if='user && user.avatar && user.avatar.imageUrl', :src='user.avatar.imageUrl.includes("svg") ? user.avatar.imageUrl : user.avatar.imageUrl')
        div.secondary--text.f4(v-else-if='user.info && user.info.prenom && user.info.nom') {{user.info.prenom[0]}}.{{user.info.nom[0]}}.
      v-list-item-content.secondary--text
        //- v-list-item-subtitle.o-80(v-if='user.niveaux.length > 0') {{user.niveaux[0].titre}}
        v-list-item-subtitle.secondary--text.o-80(v-if='user.lastSignInAt') Dernière connexion: {{formatDate(user.lastSignInAt)}}
        v-list-item-title.card-title(v-if='user.info && user.info.prenom && user.info.nom') {{user.info.prenom}} {{user.info.nom}}
        v-list-item-subtitle.black--text.i(v-if='user.info && user.info.username') @{{user.info.username}}

    v-expansion-panels.animated.fadeIn(flat, light)
      v-expansion-panel(color='white', depressed, flat, :disabled='!listeExo || listeExo.length === 0', style='background-color: transparent;')
        v-expansion-panel-header(depressed, flat, expand-icon="mdi-menu-down")
          .analyse__wrapper.lighten-4.secondary--text(:class='getColorFromResult(markGlobal)')
            span Tendance globale des efforts
            v-spacer
            template(v-if='$store.state.App.mobileTemplate')
              .color-tag(v-if='listeExo.length > 0', :class='getColorFromResult(markGlobal)')
              span(v-else) N/D
            v-chip.white.secondary--text(v-else, :small='!$store.state.App.mobileTemplate', :x-small='$store.state.App.mobileTemplate')
              template(v-if='markGlobal > 75')
                span.analyse-value Super
                div.color-tag(:class='getColorFromResult(markGlobal)')
              template(v-else-if='markGlobal >= 60')
                span.analyse-value Progrès
                div.color-tag(:class='getColorFromResult(markGlobal)')
              template(v-else-if='markGlobal >= 0 && listeExo.length > 0')
                span.analyse-value À améliorer
                div.color-tag(:class='getColorFromResult(markGlobal)')
              template(v-else-if='markGlobal === 0 && listeExo.length === 0')
                span.analyse-value N/D
                //- div.color-tag.black
              template(v-else-if='loadingCalc')
                span.analyse-value Calcul...
                div.color-tag(:class='getColorFromResult(markGlobal)')
        v-expansion-panel-content(depressed, flat, light, :color='$store.state.Preferences.darkMode ? "white" : "light"')
          note-enfant(:user='user', :listExo='listeExo')
    v-card-actions.actions__wrapper.animated.fadeIn()
      v-btn.secondary-action(depressed, rounded, small, outlined, :color='$store.state.Preferences.darkMode ? "secondary" : "primary"', @click='connectToEnfant(user)') Aller dans la classe élève
      v-btn.secondary-action(depressed, rounded, small, outlined, :color='$store.state.Preferences.darkMode ? "secondary" : "primary"', @click='goChat') Message
      div.flex-grow-1(v-if='$store.state.App.windowSize.width > 600')
    div.main-button
      v-btn.b.main-action(block, depressed, rounded, :to="{ path: 'enfants/' + user.id }", color='primary', large)
        span.f5 Voir les détails
</template>
<style lang='sass' scoped>
@import 'src/styles/components/widgets/_enfantsCards'

</style>
