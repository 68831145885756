export default {
  methods: {
    getColorFromResult (value) {
      if (parseInt(value) > 75) {
        return 'success'
      } else if (parseInt(value) >= 60) {
        return 'amber'
      } else if (parseInt(value) > 0) {
        return 'error'
      } else {
        return 'grey'
      }
    }
  }
}
