export default {
  methods: {
    async connectToEnfant (eleve) {
      this.showDialogLoading = true
      // on va récupéré le bearer pour connecter l'utilisateur et le passé dans l'url
      // on doit recupere le access code
      await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/identificationSalleClasse.gql'),
        variables: {
          username: eleve.username ? eleve.username : eleve.info.username,
          accessCode: eleve.access_code ? eleve.access_code : eleve.info.accessCode
        }
      }).then(({ data }) => {
        if (data.identificationSalleClasse) {
          window.open('https://eleve.dokoma.com/#/connect?token=' + data.identificationSalleClasse + '&origin=parent', '_blank')
          this.showDialogLoading = false
        }
      })
    }
  }
}
