<script>
import ScoreFunctions from '@/mixins/scoreFunctions.js'

import { groupBy } from 'lodash-es'
export default {
  name: 'noteEnfant',
  mixins: [ScoreFunctions],
  props: {
    user: { required: true, type: Object },
    listExo: { required: false, type: Array }
  },
  computed: {
    notes () {
      return groupBy(this.listExo, 'graph.id')
    },
    matieres () {
      let temp = []
      this.listExo.forEach((e) => {
        if (e.graph.matiere_principales.length > 0) {
          e.graph.matiere_principales.forEach((m) => {
            if (!temp.find(t => t.id === m.id)) {
              temp.push(m)
            }
          })
        }
      })
      // console.log('matieres', temp)
      return temp
    }
  },
  methods: {
    moyenne (elem) {
      if (!elem) {
        const temp = this.listExo.filter(e => e.graph.matiere_principales.length === 0 || !e.graph.matiere_principales)
        let note = 0
        let tot = 0
        temp.forEach((e) => {
          if (e.mark) {
            note += e.mark
            tot++
          }
        })
        return Math.round((note / tot), 2)
      } else {
        const temp = this.listExo.filter(e => e.graph.matiere_principales.find(m => m.titre === elem.titre))
        let note = 0
        let tot = 0
        temp.forEach((e) => {
          if (e.mark) {
            note += e.mark
            tot++
          }
        })
        return Math.round(note / tot, 2)
      }
    }
  }
}
</script>
<template lang="pug">
  .note-enfant
    div.secondary-content
      v-list.stats-wrapper(color='transparent', v-if='notes', dense)
        v-list-item.single-stat(v-for='(matiere, index) in matieres', :key='index', style='padding: 0; margin-bottom: 0;')
          div.f6 {{matiere.titre}}
          v-divider.mh2
          v-chip(small, :color='getColorFromResult(moyenne(matiere))') {{ moyenne(matiere) }} %
        template(v-if='listExo.filter(e => e.graph.matiere_principales.length === 0).length > 0')
          v-list-item.single-stat(style='padding: 0; margin-bottom: 0;')
            div.f6 Non classés
            v-divider.mh2
            v-chip(small, :color='getColorFromResult(moyenne(false))') {{ moyenne(false) }} %
</template>
<style lang='sass' scoped>

</style>
